import React from 'react';
import styled, { css } from 'styled-components';
import { FigureProps } from './types';

const TransitionHover = css`
  transition: transform 0.375s cubic-bezier(0.12, 0.48, 0.42, 0.96), opacity 0s !important;
`;

const TransitionDefault = css`
  transition: transform 0.375s cubic-bezier(0.12, 0.48, 0.42, 0.96), opacity 0s 0.375s !important;
`;

const StyledFigure = styled.figure<{ lessAnimation: boolean }>`
  position: relative;
  pointer-events: none;

  ${TransitionDefault};
  margin: 0;

  &:before {
    ${TransitionDefault};
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background-color: var(--color-citrus);
    pointer-events: none;
    opacity: 0;
  }

  img {
    ${TransitionDefault};
    margin: auto;
    width: 100%;
    pointer-events: none;
  }

  &.hover {
    @media screen and (min-width: 640px) {
      transform: translateY(-0.25rem) scale(1.02);
    }

    &:before {
      ${TransitionHover};
      opacity: 1;

      @media screen and (min-width: 640px) {
        transform: ${(props) => (props.lessAnimation ? 'translate(-0.625rem, 0.625rem)' : 'translate(-1rem, 1rem)')};
      }
    }

    img {
      ${TransitionHover};
    }
  }
`;

const Figure: React.FC<FigureProps> = ({ children, hover, lessAnimation }) => (
  <StyledFigure className={hover && 'hover'} lessAnimation={lessAnimation}>
    {children}
  </StyledFigure>
);

export default Figure;
