import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { SEOProps } from './types';

const ArticleStructuredData: React.FC<SEOProps> = ({ title, image, datePublished, dateModified }) => {
  const location = useLocation();

  return (
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            "headline": "${title}",
            "image": "${location.origin}${image}",
            "datePublished": "${datePublished}",
            "dateModified": "${dateModified}",
            "author": {
              "@type": "Organization",
              "name": "Osynlig",
              "url": "${location.origin}"
            }
          }
        `}
      </script>
    </Helmet>
  );
};

export default ArticleStructuredData;
