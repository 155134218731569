/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import ArticleStructuredData from './ArticleStructuredData';
import { SEOProps } from './types';

const canonicalPath = (typename: string, path: string): string => {
  const paths = {
    CMS_Page: '',
    CMS_Article: 'artiklar/',
    CMS_Case: 'case/',
  };

  const slug = `${path === 'startsida' ? '' : `${path}/`}`;

  return `https://osynlig.se/${paths[typename]}${slug}`;
};

const SEO: React.FC<SEOProps> = ({
  description,
  lang = 'sv',
  title,
  image,
  slug,
  type,
  datePublished,
  dateModified,
  isArticle,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  const meta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ];

  if (image !== null) {
    meta.push({
      property: `og:image`,
      content: image,
    });
  }

  return (
    <>
      <Helmet htmlAttributes={{ lang }} title={title} titleTemplate="%s | Osynlig" meta={meta}>
        <link rel="canonical" href={canonicalPath(type, slug)} />
        <script type="text/javascript">
          {`
          (function(ss,ex){ window.ldfdr=window.ldfdr||function(){(ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));}; (function(d,s){ fs=d.getElementsByTagName(s)[0]; function ce(src){ var cs=d.createElement(s); cs.src=src; cs.async=1; fs.parentNode.insertBefore(cs,fs); }; ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js'); })(document,'script'); })('lYNOR8x6vYG4WQJZ');
          `}
        </script>
      </Helmet>
      {isArticle && (
        <ArticleStructuredData title={title} image={image} datePublished={datePublished} dateModified={dateModified} />
      )}
    </>
  );
};

export default SEO;
